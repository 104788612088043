<script>
import Layout from "../../layouts/main";
import axios from "axios";
import CryptoJS from 'crypto-js';

export default {
    components: {
        Layout
    },
    data() {
        return {
            notices: [],
            app_URL: process.env.VUE_APP_URL,

        }
    },
    created() {
        this.getNotice();
    },
    methods: {
        getNotice() {
            axios.post(this.app_URL + "api/getAllNotice").then(res => {
                this.notices = res.data;
            })
        },
        completeNotice(id) {
            axios.post(this.app_URL + "api/completeNotice", { id: id }).then(() => {
                this.$store.state.isAccepted = true
                this.$store.state.notificationData = 'Notice Status Updated Successfully'
                setTimeout(() => {
                    this.$store.state.isAccepted = false
                    this.$store.state.notificationData = null
                }, 2500);
                this.getNotice();
            })
        },
        encode(id) {
            return encodeURIComponent(
                CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
            );
        },
    }


};
</script>

<template>
    <Layout>
        <main class="py-4">
            <div class="row mb-3">
                <div class="col">
                    <h2 class="font-size-22 text-theme-dark">Notices</h2>
                </div>
            </div>
            <div v-if="notices.length > 0">
                <div class="notify" v-for="(notice, index) in   notices  " :key="index">
                    <div class="card mb-3 position-relative">
                        <div class="card-body p-3">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <div class="d-flex pb-1 align-items-center">
                                        <i class="mdi mdi-alert-circle-outline text-theme fs-4 me-2"></i>
                                        <router-link :to="`single_applicant/${encode(notice.form_id)}`"
                                            @click="$store.state.applicantCstep = notice.step_id">
                                            <p class="font-size-14 m-0 text-theme">{{ notice.notice }}.</p>
                                        </router-link>
                                    </div>
                                    <!-- <span class="dateTimeStyle text-muted d-flex"> -->
                                    <i class="mdi mdi-calendar-clock me-2"></i>
                                    <span class="bg-theme-soft squareListTask">
                                        {{ new Date(notice.created_at).getDate() }} {{ new
                                            Date(notice.created_at).toLocaleString('en-US', { month: 'short', year: 'numeric' })
                                        }}
                                    </span>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-sm btn-secondary" @click="completeNotice(notice.id)"
                                        v-if="notice.status == 0">
                                        Incomplete
                                    </button>
                                    <button class="btn btn-sm btn-success" @click="completeNotice(notice.id)"
                                        v-if="notice.status == 1">
                                        Complete
                                    </button>
                                </div>
                                <div claSS="col-3 text-end">
                                    <span class="dateTimeStyle text-muted font-size-16">
                                        <router-link :to="`/single_applicant/${this.encode(notice.user.id)}`">{{
                                            notice.user.firstName }}
                                            {{ notice.user.surName }}</router-link>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p>Your application is under <strong>Processed.</strong> We will update you once your application will be changed</p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p>Your application seems to be <strong>Incomplete.</strong> Please follow the link to update your details and re-submit your application. <a href="#" class="text-theme">https://www.gmail.com</a></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p><strong>Congratulations ,</strong> Your application has been <strong>Approved.</strong></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p><strong>Sorry,</strong>  We regret to inform you that your application has been  <strong>Rejected.</strong></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div> -->
                </div>
            </div>
            <div v-else>
                <p class="text-center">Not Data Yet</p>
            </div>
        </main>
    </Layout>
</template>